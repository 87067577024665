<template>
  <div class="orderReport container">
	<p class="h6 text-center text-primary"><b>{{ orderType }} - Quantities</b></p>    
	<br>

    <!--------------------------------- Search Criteria -------------------------------------------->

	<CCard :color="'secondary'">
		<CCardBody>
			 
			<form class="form-inline">
				<div class="form-group">
					<label class="font-weight-bold mr-1" for="fromdate">    From:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-2" 
							v-model="fromdate" name="fromdate"></datepicker>

					<label class="font-weight-bold mr-1" for="todate">To:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-4" v-model="todate" name="todate"></datepicker>

					<select v-model="timescale" class="form-control form-select mr-4">
						<option selected value="Aggregate">Aggregate</option>
						<option value="Daily">Daily</option>
						<option value="Weekly">Weekly</option>
						<option value="Monthly">Monthly</option>
					</select>

					<CButton color="dark" v-on:click.prevent="serverCall('getsaleQtyData')">Get Data</CButton>
				</div>
			</form> 

			<div class="row">
				<div class="col-sm-3 text-left">
					<label class="col-form-label font-weight-bold text-left">Location:</label>
					<select class="form-control" v-model="selLocation">
						<option v-for="loc in userLocations">
							<option value="loc">{{ loc }}</option>
						</option>
					</select>
				</div>

				<div class="col-sm-3 text-left">
					<label class="col-form-label font-weight-bold text-left">Invoice?</label>
					<select class="form-control" v-model="selInvoiceIssued">
						<option value="NA">NA</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>

				<div class="col-sm-3 text-left">					
					<label class="col-form-label font-weight-bold text-left">GSTN?</label>
					<select class="form-control" v-model="selGSTN">
						<option value="NA">NA</option>
						<option value="Yes">Yes</option>
						<option value="No">No</option>
					</select>
				</div>
			</div>
		</CCardBody>
	</CCard>

 <!--------------------------------- Main Page -------------------------------------------->

	<div class="text-center">
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>
	<br>


	<div>
		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:row-style-class="rowStyleClassFn"
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table condensed"
			>
			<div slot="table-actions">
				<CButton color="dark" class="mr-2" v-on:click.prevent="downloadReport()">Download Report</CButton>
			</div>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field != 'category'">
					<span>{{props.formattedRow[props.column.field].toLocaleString('en-IN')}}</span> 
				</span>
				<span v-else>
					{{props.formattedRow[props.column.field]}}
				</span>
			</template>
		</vue-good-table>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'orderReport',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			columns: [],
			rows: [],
			dataList: [],

			orderType: this.$route.params.orderType,

			reportLongName: "",
			reportData: "",
			fromdate: null,
			todate: null,
			timescale: "Aggregate",
			selLocation: 'All',
			userLocations: [],
			selInvoiceIssued: 'NA',
			selGSTN: 'NA',

			disabledDates: {
				//to: new Date(2016, 0, 5), // Disable all dates up to specific date
				from: new Date(), // Disable all dates after specific date
			},

			alert: null,
			loading: false,
		};
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		this.fromdate = new Date()
		this.todate = new Date()
		this.orderType = this.$route.params['orderType']

		if (this.$store.state.mod_SaleOrder.locations)
			this.userLocations = this.$store.state.mod_SaleOrder.locations.map((x) => x.name )
		this.userLocations.push('All')

	},

	methods: {
		// paymentSummary['paid'].toLocaleString('en-IN')
        formatNumberFn: function(value) {
			let ret = new Intl.NumberFormat('en-IN').format(value.toFixed(2))
			console.log(ret)
		  	return (ret)
          	//return value.toFixed(2)
        },
		
		rowStyleClassFn(row) {
			if (row.color == 1){
				return 'active'
			}
		},

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getsaleQtyData":
								self.getsaleQtyData(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})

		},
		

        // Get Salet-QtyData for the given dates.
        getsaleQtyData(authToken) {
            var self = this
			self.alert = null

            if (!this.fromdate || !this.todate || !this.orderType){
                self.alert = "Either fromdate or todate or orderType not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Report Data..."

			var postJson = {
				'orderType': this.orderType,
				'fromDate': this.getMidDay(this.fromdate),
				'toDate': this.getMidDay(this.todate),
				'timescale': this.timescale,
				'selLocation': this.selLocation,
				'selInvoiceIssued': this.selInvoiceIssued,
				'selGSTN': this.selGSTN
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetSaleQtyData/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("GetSaleQtyData - error:" + response.data.err)
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.dataList
						self.reportLongName = self.orderType + " Quantity - " + fdStr + " To " + tdStr
						self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},


		downloadReport() {
			if (this.reportData==[]){
				alert("No report data.")
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},

	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.active {
		color : green;
	}
</style>

